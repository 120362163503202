// 主色
$theme-color: #42c3aa;
// 辅助色-橙黄
$theme-yellow: #fda82e;
// 辅助色-红
$theme-red: #fb887f;
// 辅助色-蓝
$theme-blue: #60acf2;
// 辅助色-浅蓝
$theme-cyan: #96befb;

// 标题文字
$title-color: #000000;
// 正文文字
$content-color: #595959;
// 次要文字 （导航未选中文字，更多）
$assist-color: #899699;
// 描述文字 （图例及列表中的描述）
$note-color: #9ea5ae;

// 其它颜色
// 主色-浅
$theme-color-light: #def3ee;
// 主色-浅灰
$theme-color-grey: #f2f9f9;
// 辅助色-浅色橙黄
$theme-yellow-light: #fff2e2;
// 辅助色-浅红
$theme-red-light: #f5dedf;
// 辅助色-浅蓝
$theme-blue-light: #e9f2ff;
// 辅助色-灰 (模块背景的描边)
$other-grey: #e3e6e4;
// 辅助色-浅灰
$other-grey-light: #f0f2f5;

$text-color: #ffffff;
$part-common-width: 30vw;

@font-face {
  font-family: env-number;
  src: url(../../styles/fonts/numbers.ttf);
}

.big-screen-container {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0 20px 20px 20px;
  flex-direction: column;
  color: #fff;
  background-image: url('../../assets/images/bg.png');
  background-size: 100% 100%;

  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 9%;
    position: relative;

    &-title {
      position: relative;
      top: 10px;
      text-align: center;
      font-size: 38px;
      font-style: italic;
      letter-spacing: 6px;
      text-shadow: 0 0 5px $theme-color, 0 0 10px $theme-color, 0 0 10px $theme-color;
    }
  }

  &-top {
    display: flex;
    // flex: 1;
    height: 55%;

    &-left {
      display: flex;
      width: $part-common-width;
      flex-direction: column;

      .product-line-part {
        position: relative;
        padding: 23px 37px;

        .italic-text {
          font-style: italic;
          margin-left: 20px;
        }

        &-img-container {
          position: relative;
          width: 100%;
          margin: 10%;
          white-space: nowrap;

          img {
            width: 100%;
          }

          .product-line-part-product {
            color: rgb(252, 211, 45);
            position: absolute;
          }

          .product-line-part-total {
            color: #fff;
            position: absolute;
          }

          .product-line-part-pollution {
            color: rgb(45, 254, 212);
            position: absolute;
          }
        }
      }
    }

    &-center {
      display: flex;
      flex: auto;
      padding: 40px 14px 18px 14px;
    }

    &-right {
      display: flex;
      width: $part-common-width;
      flex-direction: column;
      justify-content: space-around;

      .runtime-part {
        display: flex;
        justify-content: center;
        align-items: center;
        &-font-type1 {
          background-clip: text;
          color: transparent;
          font-size: 16px;
          padding-right: 24px;
          background-image: linear-gradient(180deg, #fcfaf2, #edcc47);
        }
        &-font-type2 {
          background-clip: text;
          color: transparent;
          font-size: 16px;
          padding-right: 24px;
          background-image: linear-gradient(180deg, #d7faf3, #62f0d3);
        }

        .runtime-progress {
          position: relative;
          overflow: hidden;

          &-line {
            .ant-progress-inner {
              border-radius: 0;
              overflow: hidden;
            }
          }

          &-block {
            position: absolute;
            z-index: 10;
            height: 8px;
            width: 5px;
            background-color: #fff;
            top: calc(50% - 3px);
            transform: skewX(-30deg);
            transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
          }

          &-center-line {
            position: absolute;
            z-index: 5;
            top: 11px;
            height: 4px;
            background-color: #00000033;
            transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
          }
        }
      }

      .BigScreenProductPartRow {
        text-align: center;
        display: flex;
        align-items: center;
        height: 90%;
        width: 100%;
        padding-top: 16px;

        img {
          max-width: 134px;
          width: 40%;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #ffffff;
          span {
            display: inline-block;
            margin: 0 5px;
            font-size: 32px;
            font-family: 'env-number';
            line-height: 38px;
            color: #ffffff;
          }
        }
      }
    }
  }

  &-bottom {
    display: flex;
    height: 35%;
    min-height: 35%;
    max-height: 35%;

    &-left {
      flex: auto;
    }

    &-right {
      width: 37%;

      .big-screen-timeline {
        height: 100%;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 43px;

        &-item {
          display: flex;
          align-content: center;
          align-items: center;
          font-size: 16px;
          height: 3rem;

          &-date {
            display: flex;
            flex-direction: column;
            padding-top: 12px;

            span:nth-child(2) {
              font-size: 12px;
              color: #99a5a2;
            }
          }

          &-icon {
            margin: 0 36px;
            position: relative;

            i {
              position: absolute;
              font-size: 12px;
              color: #fff;
              top: 6px;
              left: 9px;
            }
          }

          &-company {
            display: flex;
            flex-direction: column;
            padding-top: 12px;

            &-badge {
              font-size: 10px;
              border: solid 1px #f2cb30;
              color: #f2cb30;
              padding: 0 4px;
              border-radius: 5px;
              max-width: 35px;
              margin-right: 8px;
            }

            span:nth-child(2) {
              font-size: 12px;
              color: #99a5a2;
            }
          }

          &-status {
            font-size: 12px;
            padding: 2px 6px;
            background: radial-gradient(circle, #287464 0%, #42c3aa 100%);
            border-radius: 6px;
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.bigScreen_common_title {
  background: url(../../assets/images/title_bg.png) no-repeat;
  background-size: contain;
  display: flex;
  width: 450px;
  height: 36px;
  font-size: 16px;
  color: $text-color;
  padding-left: 47px;
  line-height: 2.1;
  margin-bottom: 10px;

  img {
    margin-left: 16px;
    margin-top: 10px;
  }
}

// 内容容器
.part-content-container {
  position: relative;
  background: transparentize(#02211b, 0.5);
  display: flex;
  align-items: center;
  height: 85%;

  &-angle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent #00ffcc;
    border-width: 0 0 5px 5px;
  }

  .nodata {
    align-self: center;
    justify-self: center;
    text-align: center;
    width: 100%;

    div {
      margin-top: 13px;
      font-size: 14px;
      color: #097a64;
    }
  }

  .nodata-rank {
    align-self: center;
    justify-self: center;
    text-align: center;
    width: 100%;

    img{
      height: 50%;
      width: 35%;
    }
  }

  .left-top {
    top: 0;
    left: 0;
  }

  .right-top {
    top: 0;
    right: 0;
    transform: rotate(90deg);
  }

  .right-bottom {
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
  }

  .left-bottom {
    bottom: 0;
    left: 0;
    transform: rotate(270deg);
  }

  .ant-carousel {
    height: 100%;
    width: 100%;
  }
}
