// 主色
$theme-color: #42c3aa;
// 辅助色-橙黄
$theme-yellow: #fda82e;
// 辅助色-红
$theme-red: #fb887f;
// 辅助色-蓝
$theme-blue: #60acf2;
// 辅助色-浅蓝
$theme-cyan: #96befb;

// 标题文字
$title-color: #000000;
// 正文文字
$content-color: #595959;
// 次要文字 （导航未选中文字，更多）
$assist-color: #899699;
// 描述文字 （图例及列表中的描述）
$note-color: #9ea5ae;

// 其它颜色
// 主色-浅
$theme-color-light: #def3ee;
// 主色-浅灰
$theme-color-grey: #f2f9f9;
// 辅助色-浅色橙黄
$theme-yellow-light: #fff2e2;
// 辅助色-浅红
$theme-red-light: #f5dedf;
// 辅助色-浅蓝
$theme-blue-light: #e9f2ff;
// 辅助色-灰 (模块背景的描边)
$other-grey: #e3e6e4;
// 辅助色-浅灰
$other-grey-light: #f0f2f5;

$text-color: #ffffff;
$note-color: #9ea5ae;
$text-color: #ffffff;

.dashboardViewStyle {
  margin: 0 !important;
  border-radius: 0 !important;
  overflow-y: auto;
  .ant-select {
    color: rgba(60, 195, 170, 0.85) !important;
  }
  //.ant-picker-suffix {
  //  background: #f0f2f5;
  //  padding: 5.5px;
  //  position: absolute;
  //  right: -11px;
  //}
}
// 工作台-企业总览样式
.ItemViewStyle {
  width: 100%;
  margin-top: 16px;
  margin-left: 0px !important;
  .colBox {
    background: rgba(255, 255, 255, 0.39);
    border: 1px solid #e3e6e4;
    padding: 3%;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &::before {
      // content: '今日';
      content: '\4eca\65e5';
      position: absolute;
      top: 10px;
      right: 12px;
      color: #899699;
      line-height: 17px;
      font-size: 12px;
    }
    .pic {
      width: 60px;
      height: 60px;
      line-height: 60px;
      background: rgba(255, 242, 226, 0.39);
      border-radius: 50%;
      text-align: center;
      .iconfont {
        font-size: 28px;
      }
    }
  }
  .gutter-row {
    .col {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      position: relative;
      .value {
        font-size: 26px;
        color: #000;
        font-weight: 600;
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .company {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      color: #000000;
      opacity: 0.65;
      padding-left: 16px;
      width: 58%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .gutter-row:nth-child(4) {
    .colBox::before {
      content: '';
    }
  }
}

.cardBox {
  .title {
    color: #000;
    font-weight: 600;
    font-size: 17px;
  }
  .cardItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .cardTitle {
      margin-top: 0.2rem;
    }
  }
}
.cardItemName {
  color: #595959;
  font-size: 13px;
}
.cardItemValue {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
}
.ant-card-head {
  margin: 0 1.3rem !important;
  padding: 0 !important;
}
.ant-card-body {
  padding: 0.25rem 1.3rem !important;
}
.ant-card-head-title {
  padding: 5px 0 !important;
}
.userDashooard {
  > .ant-col,
  > div {
    width: 100%;
    margin-bottom: 16px;
    background: rgba(255, 255, 255, 0.39);
    border: 1px solid #e3e6e4;
    border-radius: 12px;
  }
}

.timeLineContainer {
  .ant-timeline-item {
    font-size: 12px !important;
    padding-bottom: 15px !important;
  }
  .ant-timeline-item-content {
    p {
      margin-bottom: 0;
    }
  }
  .ant-timeline-item_time {
    color: #595959;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-timeline-item_name {
    color: $note-color;
  }
  .ant-timeline-item-head {
    p {
      margin-bottom: 0px;
    }
  }
  .ant-timeline-item-tail {
    border-left: 1px dotted #d1d1d1;
  }
}

// 企业生产
.enterpriseProductionStyle {
  > .gutter-row {
    height: 20vh;
    padding: 14px;
    width: 100%;
    .productionListStyle {
      > .ant-col {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .state {
          height: 30px;
          font-size: 22px;
          font-weight: 500;
          margin: 5px 0 0;
        }
        .iconState {
          width: 100%;
          max-width: 64px;
        }
        .occupationBitmap {
          width: 100%;
          max-width: 165px;
        }
      }
      .productionItemStyle {
        width: 100%;
        .iconNotData {
          max-width: 29px;
          width: 100%;
          vertical-align: bottom;
          margin-right: 10px;
        }
        .notDataTile {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          color: $note-color;
        }
        .ant-col {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 8px 0;
          padding: 8px 6%;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .type {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
        }
        .value {
          font-size: 22px;
          font-weight: 500;
          line-height: 30px;
        }
        .grade {
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          color: #000000;
          opacity: 0.65;
        }
        .day {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: $note-color;
        }
      }
    }
  }
}

// tab切换
.ant-radio-group {
  text-align: center !important;
  background: rgba(240, 242, 245, 0.69) !important;
  border-radius: 5px !important;
  padding: 4px 15px !important;
}
.ant-radio-button-wrapper {
  border: none !important;
  border-radius: 15px !important;
  margin: 0 2px !important;
  padding: 0 12px !important;
  height: 22px !important;
  line-height: 20px !important;
  font-size: 12px !important;
  background: rgba(240, 242, 245, 0.69) !important;
  color: $note-color !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #000000 !important;
  font-size: 12px !important;
  border-radius: 15px !important;
  background: $text-color !important;
}
.ant-radio-button-wrapper:first-child {
  border: none !important;
  border-radius: 15px !important;
}
.ant-radio-button-wrapper:not(:first-child):before {
  width: 0 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: $text-color !important;
  color: $note-color !important;
}
