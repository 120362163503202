// 主色
$theme-color: #42c3aa;
// 辅助色-橙黄
$theme-yellow: #fda82e;
// 辅助色-红
$theme-red: #fb887f;
// 辅助色-蓝
$theme-blue: #60acf2;
// 辅助色-浅蓝
$theme-cyan: #96befb;

// 标题文字
$title-color: #000000;
// 正文文字
$content-color: #595959;
// 次要文字 （导航未选中文字，更多）
$assist-color: #899699;
// 描述文字 （图例及列表中的描述）
$note-color: #9ea5ae;

// 其它颜色
// 主色-浅
$theme-color-light: #def3ee;
// 主色-浅灰
$theme-color-grey: #f2f9f9;
// 辅助色-浅色橙黄
$theme-yellow-light: #fff2e2;
// 辅助色-浅红
$theme-red-light: #f5dedf;
// 辅助色-浅蓝
$theme-blue-light: #e9f2ff;
// 辅助色-灰 (模块背景的描边)
$other-grey: #e3e6e4;
// 辅助色-浅灰
$other-grey-light: #f0f2f5;

$text-color: #ffffff;
.login-box {
  display: flex;
  flex: 1;
  height: 100%;
  align-self: center;
  position: relative;
  flex-direction: row-reverse;

  .login-image-box {
    position: absolute;
    top: 80px;
    right: auto;
    bottom: 80px;
    left: 0;
    // inset: 80px auto 80px 0;

    img {
      height: 100%;
    }
  }

  .login-content-box {
    position: relative;
    align-self: center;
    min-width: 360px;
    justify-self: center;
    padding: 20px;
    // margin-right: 193px;
    background-color: #ffffffbd;
    border-radius: 20px;

    .login-page-form {
      padding: 0 2px;
    }

    .login-getcode-button {
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 26px;
        top: 0;
        left: -5px;
        border: 1px solid #e3e6e4;
      }
    }

    .login-title {
      width: 360px;
      margin-bottom: 76px;
    }

    h1 {
      color: #000000;
      font-size: 36px;
      font-weight: 500;
      line-height: 50px;
      opacity: 0.85;
      margin-bottom: 80px;
    }

    .ant-tabs {
      margin: 0 auto;
      align-self: center;

      .ant-tabs-nav {
        margin-bottom: 58px;
      }

      .ant-tabs-tab-btn {
        font-size: 20px;
      }
      // tabs激活样式
      .ant-tabs-tab .ant-tabs-tab-active {
        color: $theme-color !important;
      }
    }

    .ant-form-item-extra {
      min-height: unset;
    }

    .back-to-login-btn {
      border-radius: 27px !important;

      &:hover {
        color: $theme-color !important;
        border-color: $theme-color-light !important;
      }
    }
  }

  .login-page-form-button {
    border-radius: 27px !important;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus{
    box-shadow: 0 0 0 2px transparentize($theme-color, 0.5);
  }
}
