@font-face {
  font-family: "iconfont"; /* Project id 2363461 */
  src: url('iconfont.woff2?t=1701493262359') format('woff2'),
       url('iconfont.woff?t=1701493262359') format('woff'),
       url('iconfont.ttf?t=1701493262359') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_plc:before {
  content: "\e63e";
}

.icon_db_down:before {
  content: "\e66e";
}

.icon_down:before {
  content: "\e64d";
}

.iconshexiangtou:before {
  content: "\e7c9";
}

.iconrefresh-1-copy:before {
  content: "\e60f";
}

.iconshuaxin1:before {
  content: "\e607";
}

.iconshuaxin:before {
  content: "\e66d";
}

.iconfuzhi1:before {
  content: "\e7b5";
}

.icon_env_login_psw:before {
  content: "\e7b3";
}

.icon_env_login_code:before {
  content: "\e7b1";
}

.icon_env_login_accout:before {
  content: "\e7b2";
}

.iconxiangqing1:before {
  content: "\e71e";
}

.iconbaojing1:before {
  content: "\e7b0";
}

.iconbaojing:before {
  content: "\e7af";
}

.iconbiaotisanjiao:before {
  content: "\e7ae";
}

.iconchakanxiangqing2:before {
  content: "\e60a";
}

.icon_env_account:before {
  content: "\e7ad";
}

.icon_env_log:before {
  content: "\e7ac";
}

.icon_env_dashboard:before {
  content: "\e79b";
}

.icon_env_workbench:before {
  content: "\e79c";
}

.icon_env_agent:before {
  content: "\e79e";
}

.icon_env_alert2:before {
  content: "\e79f";
}

.icon_env_app:before {
  content: "\e7a2";
}

.icon_env_data:before {
  content: "\e7a3";
}

.icon_env_pollution:before {
  content: "\e7a9";
}

.icon_env_role:before {
  content: "\e7aa";
}

.icon_env_setting:before {
  content: "\e7ab";
}

.iconfuzhi:before {
  content: "\e720";
}

.iconxiala:before {
  content: "\e7a0";
}

.iconxiaoxi:before {
  content: "\e7a1";
}

.icon_env_devices:before {
  content: "\e794";
}

.icon_env_production:before {
  content: "\e795";
}

.icon_env_monitoring_points:before {
  content: "\e798";
}

.iconshengchanxian:before {
  content: "\e79a";
}

.icon_env_company:before {
  content: "\e79d";
}

.icon_tree_tianranqi:before {
  content: "\e793";
}

.iconxiangqing:before {
  content: "\e620";
}

.icon_deploy:before {
  content: "\e7c2";
}

.iconpeizhi:before {
  content: "\ea3f";
}

.icon_tree_mupai:before {
  content: "\e78c";
}

.icon_tree_dianji:before {
  content: "\e78a";
}

.icon_tree_menci:before {
  content: "\e771";
}

.iconlishijilu:before {
  content: "\e642";
}

.iconaddress:before {
  content: "\e6db";
}

.iconzuidiwenshidu:before {
  content: "\e761";
}

.iconpingjunwenshidu:before {
  content: "\e762";
}

.iconwenshidu_line:before {
  content: "\e75d";
}

.iconyuandian:before {
  content: "\e605";
}

.icona-zu6947:before {
  content: "\e75c";
}

.iconshuijinshebei:before {
  content: "\e757";
}

.iconCTwangguan:before {
  content: "\e758";
}

.icon_tree_dianbiao:before {
  content: "\e759";
}

.iconzanwutupian:before {
  content: "\e756";
}

.icon_tree_camera:before {
  content: "\e74e";
}

.icon_xinhao4:before {
  content: "\e747";
}

.icon_xinhao2:before {
  content: "\e748";
}

.icon_xinhao1:before {
  content: "\e749";
}

.icon_xinhao0:before {
  content: "\e74a";
}

.icon_xinhao3:before {
  content: "\e74b";
}

.icon_xinhao5:before {
  content: "\e74c";
}

.icon-wuxian-:before {
  content: "\e619";
}

.icon-wuxian-1:before {
  content: "\e61a";
}

.icon-wuxian-2:before {
  content: "\e61b";
}

.icon-wuxian-3:before {
  content: "\e61c";
}

.icon-wuxian-4:before {
  content: "\e61d";
}

.icon-wuxian-5:before {
  content: "\e61f";
}

.icondianchi-mandian:before {
  content: "\e7a4";
}

.icondianchi-gaodianliang:before {
  content: "\e7a5";
}

.icondianchi-zhongdianliang:before {
  content: "\e7a6";
}

.icondianchi-didianliang:before {
  content: "\e7a7";
}

.icondianchi-meidian:before {
  content: "\e7a8";
}

.iconright:before {
  content: "\e6a5";
}

.iconfangdajing:before {
  content: "\e625";
}

.iconleft:before {
  content: "\e613";
}

.iconfanhui:before {
  content: "\e609";
}

.iconweizhi1:before {
  content: "\e612";
}

.iconweijihuo1:before {
  content: "\e737";
}

.icona-zu6928:before {
  content: "\e724";
}

.iconshebeijihuo:before {
  content: "\e654";
}

.iconjijian_weijihuo:before {
  content: "\e618";
}

.iconjihuo1:before {
  content: "\e628";
}

.iconjihuo:before {
  content: "\e617";
}

.icon_tishi:before {
  content: "\e60c";
}

.iconform_icon_disable:before {
  content: "\e616";
}

.iconshouye:before {
  content: "\e69f";
}

.icongerengongzuotai:before {
  content: "\e626";
}

.iconshouye1:before {
  content: "\e6a4";
}

.icongongzuotai:before {
  content: "\e677";
}

.iconshebei:before {
  content: "\e630";
}

.iconshebei1:before {
  content: "\e622";
}

.iconshebeii:before {
  content: "\e6be";
}

.iconline-doughnutcharthuanxingshujutu-02:before {
  content: "\e69c";
}

.icontree:before {
  content: "\e600";
}

.iconzaixianyonghu:before {
  content: "\e6ad";
}

.icontishi:before {
  content: "\e62a";
}

.icongongzuotai1:before {
  content: "\e639";
}

.icongongzuotai2:before {
  content: "\e602";
}

.iconguanbi:before {
  content: "\e700";
}

.iconzhipai:before {
  content: "\e702";
}

.iconchongzhimima:before {
  content: "\e703";
}

.iconshujudaochu:before {
  content: "\e704";
}

.icondaochu:before {
  content: "\e705";
}

.icondaoru:before {
  content: "\e706";
}

.icongenghuan:before {
  content: "\e708";
}

.icona-zu6914:before {
  content: "\e709";
}

.iconshanchu1:before {
  content: "\e707";
}

.iconchakan1:before {
  content: "\e701";
}

.iconyiguoqi:before {
  content: "\e6bd";
}

.icon_title_report_details:before {
  content: "\e6b4";
}

.icon_title_manage_details:before {
  content: "\e6b5";
}

.icon_title_info:before {
  content: "\e6b6";
}

.icon_title_patrol_details:before {
  content: "\e6b7";
}

.icon_title_alarm:before {
  content: "\e6b8";
}

.icon_title_processing_progress:before {
  content: "\e6b9";
}

.icon_title_patrol_result:before {
  content: "\e6ba";
}

.icon_title_patrol_plan:before {
  content: "\e6bb";
}

.icon_title_peak_valley:before {
  content: "\e6bc";
}

.icon_app:before {
  content: "\e6a9";
}

.icon_hardware:before {
  content: "\e784";
}

.icon_echartLine:before {
  content: "\e69b";
}

.icon_echartBar:before {
  content: "\e69e";
}

.icon_echartRestore:before {
  content: "\e6a0";
}

.icon_download:before {
  content: "\e6a2";
}

.icon_patrol:before {
  content: "\e6a3";
}

.icon_icon_echartRestore_selected:before {
  content: "\e6a1";
}

.icon_power:before {
  content: "\e69a";
}

.icon_home:before {
  content: "\e699";
}

.icon_title_trend:before {
  content: "\e698";
}

.icon_title_heatmap:before {
  content: "\e697";
}

.icon_title_contrast:before {
  content: "\e695";
}

.icon_title_sankey:before {
  content: "\e696";
}

.icon_title_spread:before {
  content: "\e694";
}

.icon_title_power_survey:before {
  content: "\e68d";
}

.icon_title_enterprise_profile:before {
  content: "\e68e";
}

.icon_alrm_statistics:before {
  content: "\e68f";
}

.icon_power_current:before {
  content: "\e690";
}

.icon_alrm_info:before {
  content: "\e691";
}

.icon_working:before {
  content: "\e692";
}

.icon_information:before {
  content: "\e687";
}

.icon_gateway:before {
  content: "\e679";
}

.icon_device:before {
  content: "\e67f";
}

.icon_declare:before {
  content: "\e681";
}

.icon_account:before {
  content: "\e685";
}

.icon_hiddenDanger:before {
  content: "\e675";
}

.icon_hiddenDanger1:before {
  content: "\e673";
}

.icon_workBench:before {
  content: "\e674";
}

.icon_report:before {
  content: "\e668";
}

.icon_tree_single_channel_controller:before {
  content: "\e666";
}

.icon_tree_socket_selected:before {
  content: "\e65b";
}

.icon_tree_multiple_controllers:before {
  content: "\e65f";
}

.icon_tree_transformer:before {
  content: "\e661";
}

.icon_tree_switch_controller:before {
  content: "\e662";
}

.icon_tree_gateway:before {
  content: "\e663";
}

.icon_tree_sensor:before {
  content: "\e664";
}

.icon_tree_smoke:before {
  content: "\e665";
}

.icon_flat:before {
  content: "\ebde";
}

.icon_monitor:before {
  content: "\e61e";
}

.icon_analysis:before {
  content: "\e611";
}

.icon_location:before {
  content: "\e608";
}

.icon_bj:before {
  content: "\e603";
}

.icon_jc:before {
  content: "\e604";
}

.icon_role:before {
  content: "\e631";
}

.icon_title_report:before {
  content: "\e65c";
}

.icon_socket:before {
  content: "\e69d";
}

.icon_lighting:before {
  content: "\e6e3";
}

.icon_login_yzm:before {
  content: "\e64c";
}

.icon_login_password:before {
  content: "\e64a";
}

.icon_login_user:before {
  content: "\e64b";
}

.icon_right:before {
  content: "\e67c";
}

.icon_setting:before {
  content: "\e633";
}

.icon_patrol_info:before {
  content: "\e637";
}

.icon_kanBan:before {
  content: "\e682";
}

.icon_address:before {
  content: "\e6d9";
}

.icon_rise:before {
  content: "\e7f1";
}

.icon_decline:before {
  content: "\e7f2";
}

.icon_account_management:before {
  content: "\e62c";
}

.icon_change_password:before {
  content: "\e653";
}

.icon_log:before {
  content: "\e660";
}

.icon_reset:before {
  content: "\e767";
}

.icon_close:before {
  content: "\e606";
}

