// 主色
$theme-color: #42c3aa;
// 辅助色-橙黄
$theme-yellow: #fda82e;
// 辅助色-红
$theme-red: #fb887f;
// 辅助色-蓝
$theme-blue: #60acf2;
// 辅助色-浅蓝
$theme-cyan: #96befb;

// 标题文字
$title-color: #000000;
// 正文文字
$content-color: #595959;
// 次要文字 （导航未选中文字，更多）
$assist-color: #899699;
// 描述文字 （图例及列表中的描述）
$note-color: #9ea5ae;

// 其它颜色
// 主色-浅
$theme-color-light: #def3ee;
// 主色-浅灰
$theme-color-grey: #f2f9f9;
// 辅助色-浅色橙黄
$theme-yellow-light: #fff2e2;
// 辅助色-浅红
$theme-red-light: #f5dedf;
// 辅助色-浅蓝
$theme-blue-light: #e9f2ff;
// 辅助色-灰 (模块背景的描边)
$other-grey: #e3e6e4;
// 辅助色-浅灰
$other-grey-light: #f0f2f5;

$text-color: #ffffff;
.hidden-danger-container {
  height: 100%;
  padding-bottom: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.recipient-item,
.recipient-add-button {
  min-width: 530px;
  padding-right: 64px;
}

// .recipient-form {
//   flex: 1;
//   height: 0;
//   overflow: auto;
// }
