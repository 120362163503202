// 主色
$theme-color: #42c3aa;
// 辅助色-橙黄
$theme-yellow: #fda82e;
// 辅助色-红
$theme-red: #fb887f;
// 辅助色-蓝
$theme-blue: #60acf2;
// 辅助色-浅蓝
$theme-cyan: #96befb;

// 标题文字
$title-color: #000000;
// 正文文字
$content-color: #595959;
// 次要文字 （导航未选中文字，更多）
$assist-color: #899699;
// 描述文字 （图例及列表中的描述）
$note-color: #9ea5ae;

// 其它颜色
// 主色-浅
$theme-color-light: #def3ee;
// 主色-浅灰
$theme-color-grey: #f2f9f9;
// 辅助色-浅色橙黄
$theme-yellow-light: #fff2e2;
// 辅助色-浅红
$theme-red-light: #f5dedf;
// 辅助色-浅蓝
$theme-blue-light: #e9f2ff;
// 辅助色-灰 (模块背景的描边)
$other-grey: #e3e6e4;
// 辅助色-浅灰
$other-grey-light: #f0f2f5;

$text-color: #ffffff;
html,
body,
#root,
#root > * {
  height: 100%;
}

body {
  font-size: 14px;
  color: $content-color !important;
}

iframe {
  display: none;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

.amap-container {
  background-color: transparent !important;
}
.anchorBL,
.amap-logo {
  display: none !important;
}

.ant-btn,
.ant-input,
.ant-select-selector,
.ant-pagination-item-link,
.ant-pagination-item,
.ant-picker {
  border-radius: 5px !important;
}

.resource-tree-select {
  .ant-select-tree-switcher {
    position: relative;
    z-index: 99;
  }
  .ant-select-tree-checkbox {
    z-index: 100;
  }

  .ant-select-tree-switcher_open::before,
  .ant-select-tree-switcher_close::before {
    content: '';
    position: absolute;
    right: -134px;
    top: 0;
    left: 0;
    bottom: 0;
  }
}

#searchForm > .ant-row > .ant-row.ant-form-item {
  width: 100%;
  text-align: right;
  padding-right: 12px;

  .ant-col {
    flex: 1;
    max-width: 100%;
    width: 100%;

    .ant-btn:first-child {
      margin-right: 10px;
    }
  }
}

.ant-upload {
  width: 100%;
}

.env-menu-icon {
  color: #899699;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}

.ant-menu-submenu-selected,
.ant-menu-submenu-title:hover,
.ant-menu-item:hover,
.ant-menu-item-selected {
  .env-menu-icon {
    color: $theme-color;
  }
}

.flex-horizontal-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-horizontal-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.expandable {
  width: 100%;
  height: 100%;
}

// 右侧内容容器样式
.container {
  position: relative;
  height: calc(100vh - 105px);
  margin: 16px;
  padding: 16px;
  background-color: $text-color;
  border-radius: 12px;
  // overflow-y: auto;
  // 新增按钮
  .commonButton {
    margin-bottom: 16px;
  }

  //antd表样式更改
  .ant-table-thead > tr > th {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    font-family: 'PingFang SC';
    background: #f5fafa;
    color: #333333;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
    color: #595959;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 14px; //单元格的内边距，默认16px
  }

  .device-not-active {
    color: #fb887f;
  }

  .device-offline {
    color: #fda82e;
  }

  .device-online {
    color: #42c3aa;
  }
}

// 工作台标题样式
.common_title {
  display: inline-block;
  width: 100%;
  height: 36px;
  line-height: 20px;
  color: #333333;
  font-weight: 500;
  font-size: 20px;
  vertical-align: middle;
  border-radius: 10px 10px 0px 0px;
  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 20px;
    background: rgba(66, 195, 170, 0.89);
    margin-right: 10px;
    vertical-align: middle;
  }
  .more {
    float: right;
    font-size: 12px;
    cursor: pointer;
    color: #899699;
    a {
      text-decoration: none;
      color: #899699;
    }
  }
}

.hideYearStyle {
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn,
  .ant-picker-year-btn {
    display: none !important;
  }
}
